export const percentCounter = (ms) => {
    const counterBox = document.querySelector('[data-counter]');
    let counter = 0;
    if (!counterBox) return;

    const interval = setInterval(() => {
        counterBox.textContent = ++counter + '%';
        counter === 100 ? clearInterval(interval) : false;
    }, ms);
};
